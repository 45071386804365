import React from 'react'


import MenuNav from './../../composant/NavBarAdmin'
import Blog from './../Blog/Blog'
import { useEffect, useState, useRef } from "react"
import CodePix from './../../composant/CodePix/'
import axios from 'axios';
import nathalie from './../../assets/images/nathalie_capa3.jpeg'
import { useNavigate } from 'react-router-dom';

const Index = () => {
  const [menuConsulta, setMenuConsulta] = useState([]);
  const navigate = useNavigate();
  const goSobre= () => { navigate("/sobre") }
	const myRef = useRef();
	const UrlBase = ' https://ngs.adv.br/';
	const getMenuConsulta = async () => {
		fetch(UrlBase + 'menu_consulta_ativo.php')
			.then(response => response.json())
			.then(data => {
				console.log(data.length)

				setMenuConsulta(data);
			})

	}

	useEffect(() => {
		getMenuConsulta();

	}, []);
 
  const [nome, setNome] = useState([]);
    const [email, setEmail] = useState([]);
    const [telefone, setTelefone] = useState([]);
    const [motivo, setMotivo] = useState([]);
    const [mensagem, setMensagem] = useState([]);
    const onSubmit = (e) => {
      e.preventDefault();
        let endpoint = "https://ngs.adv.br/add_contato.php";
            axios.post(endpoint, {
                nome: nome,
                email: email,
                telefone: telefone,
                motivo:motivo,
                mensagem:mensagem
            }).then((res) => {
                console.log('File uploaded!');
          
            })
          
          }

  return (
    <>
      <section id="content-home">
        <MenuNav />
      </section>

      <div className='capa-home'>
       <div>
        <h1>Dra Nathalie Guimarães dos Santos</h1>
        <p class="sous-titre">Advogada e consultora juridica</p>
        </div>
        <section class="section-tarifs" id="tarifs">

          <div class="container-tarifs">
          {(Object.values(menuConsulta).map(consul =>
            <div class="cartes-tarifs">
              <h4>{consul.titre}</h4>
              <span>{consul.tarif}</span>
              <a href="/agendamento/">Agendar a Consulta</a>
            </div>
))}


          </div>

        </section>


      </div>
     
      <div className="bloc-sobre">
      <div className='image'>
            <img src= {nathalie} alt="couloir d'un temple de kyoto"/>
            </div>
            <div class='content'>
                <h3>Nathalie Guimarães, advogada criminalista.
</h3>
                <p>
                Eu, sou advogada desde 2014, e me dedico aos estudos não Direito desde a faculdade o primeiro ano de graduação como acadêmica e estagiária.
Sou atuante na área penal desde 2015. Também tenho longa experiência em Direito das Famílias e Direito Civil, porém a minha área de atual dedicação exclusiva é Direito Penal e Processo Penal.
                </p>
                <p>Me dedico às causas penais e meu foco também está no acolhimento da família que muitas vezes acaba por ser também penalizada em virtude do encarceramento do seu ente familiar.</p>
	<p>

Não há causas perdidas e não há cliente que “não tem mais jeito”.</p><p>

Não costumo gravar ou saber os apelidos dos meus clientes, pois pra mim são pessoas, cidadãos em situação passageira de restrição de liberdade.</p><p>

Eu sou advogada que acredita na ressocialização do custodiado penal, e tem certeza que o advogado deve exercer papel ativo nesse cenario social.</p><p>

Cada cliente uma nova história de um ser humano que vem carregado de dor, abandono estatal, rupturas e desestruturas familiares e psicológicas antes, durante e depois do cárcere.</p><p>

Historias que não justificam mas tentam explicar um fato social : “o encarceramento massivo de pobres e periféricos”.</p><p>

Há outros perfis em recorte, e eu sou a advogada que transita entre todos.</p><p>

Sou mulher, afro indígena e advogada.</p><p>

Muito Prazer, sou Nathalie Guimarães dos Santos.</p>
                
                <div className='icons-container'>
                <a href='https://m.facebook.com/ngs.advogada.consultora?mibextid=ZbWKwL'>
                  <div className='icons'><i class="fa-brands fa-facebook"></i>
                  <span>FaceBook</span></div></a>
                  <a href="https://wa.me/message/IHVR2VYR6OHLJ1">
                <div className='icons'>  <i class="fa-brands fa-whatsapp"></i>
                <span>whatsApp</span></div></a>
                <a href="https://instagram.com/ngs.advogada?igshid=NGExMmI2YTkyZg==">
                <div className='icons'> <i class="fa-brands fa-instagram"></i>
                <span>Instagram</span></div></a>
                
                </div>
                <button class="btn-bloc-left" onClick={goSobre} >Saiba mais</button>
            </div>
            
        </div>
        
      <Blog/>

      <section class="parallax">

        <span>Agende uma consulta - NGS-ADVOGADA</span>
      </section>

      <section class="section-contact" id="contact">

        <h2><strong>Entre </strong> em Contato</h2>

        <div class="container-form">

          <form class="form-bloc">

            <div class="form-groupe">
              <label for="prenom">Nome completo</label>
              <input type="text" placeholder="sobrenome" required id="prenom"name='nome' onChange={e=>setNome(e.target.value)} />
            </div>

            <div class="form-groupe">
              <label for="nom">E_mail</label>
              <input type="email"  required id="nom" name='email' onChange={e=>setEmail(e.target.value)}/>
            </div>
            <div class="form-groupe">
              <label for="nom">Telefone para contato</label>
              <input type="number"  required id="nom"name='telefone' onChange={e=>setTelefone(e.target.value)} />
            </div>
            <div class="form-groupe">
              <label for="nom">Motivo do Contato</label>
              <input type="text"  required id="nom"name='motivo'  onChange={e=>setMotivo(e.target.value)}/>
            </div>

            <div class="form-groupe">
            <label for="nom">Mensagem</label>
              <textarea id="nom" name='mensagem' required onChange={e=>setMensagem(e.target.value)}></textarea>
            </div>

            <div class="form-groupe">
              <input type="submit" onClick={onSubmit} value="ENVIAR" class="button-sub" />
            </div>

          </form>

        </div>

      </section>

      <footer>
        Todos os direitos reservados &copy;
      </footer>
    </>
  )
}

export default Index
